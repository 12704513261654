<template>
    <div id="app">
        <div class="header" :hidden="is_alipay||is_wxpay">
            <van-nav-bar :title="pay_status" left-arrow @click-left="closewin">
                <template #left>
                    <van-icon name="cross" size="20" color="#000000"/>
                </template>
            </van-nav-bar>
        </div>
        <div class="content pay-result">
            <van-image fit="contain" :src="state == 0?state_fail:state_successful" width="80"
                       class="pay-result-img"></van-image>
            <div class="pay-result-text">{{pay_status}}</div>
            <div class="timer" v-if="state == 0" >
                <div>剩余付款时间：</div>
                <div id="timer">{{timer}}</div>
            </div>
            <div v-if="state == 1" class="success-desc" >
               <div v-if="pay_status == '付款成功'">您已向商家 {{result.shopName}} 支付</div>
                <div v-else>退款金额</div>
            </div>
            <div v-if="state == 0" style="padding: 0px 68px;display: flex;flex-direction: column;row-gap: 20px;">
                <van-button type="default" class="order-pay-platform" round block @click="goBack">其它支付方式</van-button>
                <van-button color="#ef2505" v-if="timer!='00:00:00'" type="default" round block @click="payOrder">继续支付</van-button>
            </div>
            <div v-else class="money">￥{{result.amount}}</div>
            <div style="margin:0px 30px;font-size: .9em;text-align: left;line-height: 30px;">
                <van-divider></van-divider>
                <div style="display: flex;align-items: center;">
                    <div style="width: 100px;">订单号</div>
                    <div>{{result.orderNo}}</div>
                </div>
                <div  v-if="state == 1" style="display: flex;align-items: center;">
                    <div style="width: 100px;">付款时间</div>
                    <div>{{result.payTime}}</div>
                </div>
            </div>
        </div>
        <div class="footer" v-show="is_Top">
            <div class="zft-ad" @click="download">
                <div class="zft-ad-title">广告位</div>
                <div class="zft-ad-desc">推荐使用芝富通APP付款，更优惠！<!--<br/>每单最高可减50元--></div>
                <div class="download-now">立即下载</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Image,
        NavBar,
        Icon,
        Button,
        Divider
    } from 'vant'

    export default {
        name: "index.vue",
        components: {
            [Image.name]: Image,
            [NavBar.name]: NavBar,
            [Icon.name]: Icon,
            [Button.name]: Button,
            [Divider.name]: Divider
        },
        data() {
            return {
                state: 1,
                state_successful: require("../../assets/state_successful.png"),
                state_fail: require("../../assets/state_fail.png"),
                result: {},
                is_Top: true,
                timer:'00:00:00',
                pay_status:''
            }
        },
        mounted() {
            // this.state = this.$route.params.state
            this.init()
            this.is_Top = self == top
            console.log(new Date().getTime())
        },
        methods: {
            init() {
                if (this.is_alipay) {
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
                    document.body.appendChild(s);
                }
                const s = document.createElement('script');
                s.type = 'text/javascript';
                s.src = 'https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js';
                document.body.appendChild(s);

                window.onload = () => {
                    var mchData = {action: 'onIframeReady', displayStyle: 'SHOW_CUSTOM_PAGE'}
                    var postData = JSON.stringify(mchData)
                    parent.postMessage(postData, 'https://payapp.weixin.qq.com')
                }

                const query = this.$route.query;
                // window.document.title = query.status == 'TRADE_SUCCESS' ? '付款成功' : '付款失败'
                if(query.status == 'TRADE_SUCCESS'){
                    window.document.title = '付款成功'
                    this.pay_status = '付款成功'
                }else if(query.status == 'TRADE_REFUND'){
                    window.document.title = '退款成功'
                    this.pay_status = '退款成功'
                }else{
                    window.document.title = '付款失败'
                    this.pay_status = '付款失败'
                }
                this.state = (query.status == 'TRADE_SUCCESS'||query.status == 'TRADE_REFUND') ? 1 : 0

                const params = {
                    orderNo: query.merOrderId
                }
                const _this = this;
                this.$request.get('sys/order/getOrderPayInfoByOrderId', {params}).then((res) => {
                    console.log(res)
                    // eslint-disable-next-line no-debugger
                    if (parseInt(res.code) === 200) {
                        this.result = res.data
                        if (query.status != 'TRADE_SUCCESS') {
                            const timer = setInterval(function () {
                                const date = new Date();
                                var ts =(res.data.expireTime-date.getTime())/1000;//计算剩余的毫秒数
                                console.log(ts);
                                var hh = parseInt(ts  / 60 / 60 % 24, 10);//计算剩余的小时数
                                var mm = parseInt(ts  / 60 % 60, 10);//计算剩余的分钟数
                                var ss = parseInt(ts  % 60, 10);//计算剩余的秒数
                                hh = _this.checkTime(hh);
                                mm = _this.checkTime(mm);
                                ss = _this.checkTime(ss);
                                if(ts>0){
                                    // document.getElementById("timer").innerHTML = hh + ":" + mm + ":" + ss;
                                    _this.timer = hh + ":" + mm + ":" + ss;
                                }else if(ts<0){
                                    // document.getElementById("timer").innerHTML="00:00:00";
                                    // location.reload();
                                    _this.timer = '00:00:00'
                                    clearInterval(timer)
                                    clearInterval(timer)
                                    clearInterval(timer)
                                }
                            }, 1000)
                        }
                    }
                })

            },

            checkTime(i) {
                if (i <= 10) {
                    i = "0" + i;
                }
                return i;
            },
            closewin() {
                if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
                    window.location.href = "about:blank";
                    window.close();
                } else {
                    window.opener = null;
                    window.open("", "_self");
                    window.close();
                }
            },
            goBack() {
                const url = 'https://www.zhimadi.cn/getcode.php?auk=wallet&id=' + this.result.qrCodeDataId;
                // this.$router.back()
                // this.$router.push('home?id=')
                // location.href =
                // window.open('https://www.zhimadi.cn/getcode.php?auk=wallet_local&id='+this.result.userCollectionId,"_blank")
                // window.location.href = url
                window.location.replace(url)
                // var a = document.createElement("a");
                // a.setAttribute("href", 'https://www.zhimadi.cn/getcode.php?auk=wallet_local&id='+this.result.userCollectionId);
                // a.setAttribute("target", "_blank");
                // a.click();
            },
            download() {
                this.$router.push('download')
            },
            payOrder() {
                let that = this;
                if (this.is_alipay) {
                    // eslint-disable-next-line no-undef
                    ap.tradePay({
                        tradeNO: this.result.payUrl
                    }, function({resultCode}){
                        // ap.alert(typeof resultCode)
                        if(resultCode == '9000'){
                            that.$router.replace({name:'success',params:{state:1,amount:that.result.amount,store_info:that.store_info,uid:that.storeId}})
                        }else{
                            let msg = ''
                            if(resultCode == '8000'){
                                msg = '正在处理中'
                            }else if(resultCode == '4000'){
                                msg = '订单支付失败'
                            }else if(resultCode == '6001'){
                                msg = '用户中途取消'
                            }else if(resultCode == '6002'){
                                msg = '网络连接出错'
                            }else if(resultCode == '99'){
                                msg = '用户点击忘记密码导致快捷界面退出'
                            }
                            that.$router.replace({name:'success',params:{state:0,amount:that.amount,store_info:that.store_info,uid:that.storeId,msg:msg}})
                        }
                    });
                }
                else{
                    window.location.replace(this.result.payUrl)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    #app {
        text-align: center;
        background: white;
        height: 100vh;
    }

    .footer {
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .zft-ad {
        position: relative;
        background: #e5e5e5;
        margin: 16px;
        padding: 12px 0px;
        border-radius: 6px;
        color: #888888;

        &-desc {
            margin-top: 10px;
        }
    }

    .download-now {
        position: absolute;
        right: 0px;
        bottom: 0px;
        font-size: 12px;
        background: #000000;
        opacity: .3;
        border-radius: 12px 0px 6px 0px;
        padding: 2px 6px;
    }

    .pay-result {
        &-img {
            margin-top: 30px;
        }

        &-text {
            margin-top: 16px;
            font-weight: bold;
        }

        .fail-reason {
            margin-top: 10px;
        }

        .success-desc {
            margin-top: 40px;
            color: #888888;
        }

        .order-pay-platform {
            margin-top: 30px;
        }

        .money {
            margin-top: 10px;
            line-height: 40px;
            font-size: 30px;
        }
    }
    .timer{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        line-height: 22px;
    }
    #timer{
        color: #ef2505;
    }

</style>
